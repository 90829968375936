var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Sizes"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeSize) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', {
    staticClass: "mb-0"
  }, [_c('span', [_vm._v("Set the prop ")]), _c('code', [_vm._v("small")]), _c('span', [_vm._v(" to ")]), _c('code', [_vm._v("true")]), _c('span', [_vm._v(" to make a smaller spinner that can quickly be used within other components.")])]), _c('div', {
    staticClass: "demo-inline-spacing"
  }, [_c('div', [_c('b-spinner', {
    staticClass: "mr-1",
    attrs: {
      "small": "",
      "label": "Small Spinner"
    }
  }), _c('b-spinner', {
    attrs: {
      "small": "",
      "label": "Small Spinner",
      "type": "grow"
    }
  })], 1), _c('div', [_c('b-spinner', {
    staticClass: "mr-1",
    attrs: {
      "label": "Small Spinner"
    }
  }), _c('b-spinner', {
    attrs: {
      "label": "Small Spinner",
      "type": "grow"
    }
  })], 1), _c('div', [_c('b-spinner', {
    staticClass: "mr-1",
    staticStyle: {
      "width": "3rem",
      "height": "3rem"
    },
    attrs: {
      "label": "Large Spinner"
    }
  }), _c('b-spinner', {
    staticStyle: {
      "width": "3rem",
      "height": "3rem"
    },
    attrs: {
      "label": "Large Spinner",
      "type": "grow"
    }
  })], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }