var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Border Spinners"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeBorder) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("Use the default ")]), _c('code', [_vm._v("border")]), _c('span', [_vm._v(" type spinners for a lightweight loading indicator.")])]), _c('b-spinner', {
    attrs: {
      "label": "Loading..."
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }