var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Growing Spinner"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeGrow) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("If you don't fancy a ")]), _c('code', [_vm._v("border")]), _c('span', [_vm._v(" spinner, switch to the ")]), _c('code', [_vm._v("grow")]), _c('span', [_vm._v(" spinner by setting the prop ")]), _c('code', [_vm._v("type")]), _c('span', [_vm._v(" to ")]), _c('code', [_vm._v("'grow'")]), _vm._v(". "), _c('span', [_vm._v("While it doesn't technically spin, it does repeatedly grow!")])]), _c('b-spinner', {
    attrs: {
      "type": "grow",
      "label": "Loading..."
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }