var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Flex"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeFlex) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_vm._v(" Using flex utility classes: ")]), _c('div', {
    staticClass: "d-flex justify-content-center mb-1"
  }, [_c('b-spinner', {
    attrs: {
      "label": "Loading..."
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('strong', [_vm._v("Loading...")]), _c('b-spinner', {
    staticClass: "ml-auto"
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }