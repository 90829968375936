var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Colored Growing Spinners"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeGrowColor) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', {
    staticClass: "mb-0"
  }, [_c('span', [_vm._v("The variant prop translates the variant name to the Bootstrap v4 class ")]), _c('code', [_vm._v(".text-{variant}")]), _c('span', [_vm._v(", so if you have custom defined text color ")]), _c('code', [_vm._v("variants")]), _c('span', [_vm._v(", feel free to use them via the variant prop.")])]), _c('div', {
    staticClass: "demo-inline-spacing"
  }, _vm._l(_vm.variants, function (variant) {
    return _c('b-spinner', {
      key: variant,
      staticClass: "mr-1",
      attrs: {
        "variant": variant,
        "type": "grow"
      }
    });
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }